import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertConfig, AlertDialog } from '../dialogs/alert/alert.dialog';
import { filter, tap } from 'rxjs/operators';
import { ConfirmDialog } from '../dialogs/confirm/confirm.dialog';

@Injectable({ providedIn: 'any' })
export class RevCycloneDialogService {
	dialogOpen = false;

	constructor(private dialog: MatDialog) {}

	alert(data: AlertConfig, expectResponse = false) {
		this.dialogOpen = true;
		return this.dialog
			.open(AlertDialog, {
				data,
				panelClass: 'no-padding-dialog',
				autoFocus: false,
			})
			.afterClosed()
			.pipe(
				tap(() => this.dialogOpen = false),
				filter((confirmed) => expectResponse ? expectResponse : confirmed)
			);
	}

	confirm(data: AlertConfig, expectResponse = false) {
		this.dialogOpen = true;
		return this.dialog.open(ConfirmDialog, { data }).afterClosed()
			.pipe(
				tap(() => this.dialogOpen = false),
				filter((confirmed) => expectResponse ? expectResponse : confirmed)
			);
	}

	open(dialog: any, config?: any) {
		this.dialogOpen = true;
		return this.dialog
			.open(dialog, {
				...config,
				panelClass: 'no-padding-dialog',
				autoFocus: false,
			})
			.afterClosed()
			.pipe(
				tap((res) => this.dialogOpen = false),
				filter((response) => config?.expectResponse ? config.expectResponse : response)
			);
	}

	getDialogById(id: string) {
		return this.dialog.getDialogById(id);
	}

	closeAll() {
		this.dialog.closeAll();
	}
}
