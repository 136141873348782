import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

const EXPIRES_IN = 5;
const SECONDS_IN_MINUTE = 60;
const MILLIS_IN_SECOND = 1000;

@Component({
	selector: 'rc-inactive-warning',
	templateUrl: './inactive-warning.dialog.html',
	styleUrls: ['./inactive-warning.dialog.scss'],
	standalone: true,
	imports: [MatDialogModule, MatButtonModule],
})
export class InactiveWarningDialog implements OnDestroy, OnInit {

	seconds = EXPIRES_IN;

	timeout: ReturnType<typeof setTimeout>;

	timeLeft = signal(EXPIRES_IN);

	readableTimeLeft = computed(() => this.timeLeft() === 1 ? `${this.timeLeft()} minute` : `${this.timeLeft()} minutes`);

	interval: ReturnType<typeof setInterval>;

	constructor(private dialogRef: MatDialogRef<InactiveWarningDialog>) {}

	ngOnInit() {
		this.interval = setInterval(() => {
			this.timeLeft.update((time) => time - 1);
		}, MILLIS_IN_SECOND * SECONDS_IN_MINUTE);
		this.timeout = setTimeout(() => {
			this.dialogRef.close(false);
		}, MILLIS_IN_SECOND * SECONDS_IN_MINUTE * EXPIRES_IN);
	}

	ngOnDestroy() {
		clearTimeout(this.timeout);
		clearInterval(this.interval);
	}
}
