import { Injectable } from '@angular/core';
import { Auth, authState, signOut, User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { differenceInMinutes } from 'date-fns';
import { debounceTime, Subject } from 'rxjs';
import { RevCycloneDialogService } from '@rc/shared/dialog';
import { InactiveWarningDialog } from '../dialogs/inactive-warning/inactive-warning.dialog';
import { env } from '@rc/typings/firebase';


const EXPIRE_IN_MINUTES = 20;
const MILLIS_IN_MINUTE = 60000;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const EXPIRE_WARNING = (EXPIRE_IN_MINUTES - 5) * MILLIS_IN_MINUTE;

@Injectable({ providedIn: 'root' })
export class InactivityService {
	lastActive: Date;

	user: User;

	active: Subject<void> = new Subject();

	constructor(private auth: Auth, private router: Router, private dialog: RevCycloneDialogService) {
		authState(this.auth)
			.subscribe((user) => {
				this.user = user;

				if (this.user) {
					this.lastActive = new Date(+window.sessionStorage.getItem('rcla'));
					this.handleInactiveCheck();
				}
			});

		this.active.asObservable().pipe(debounceTime(EXPIRE_WARNING)).subscribe(() => {
			if (this.user) {
				this.dialog.open(InactiveWarningDialog, { expectResponse: true }).subscribe((response) => response ? this.updateLastActive() : this.signOut());
			}
		});
	}

	updateLastActive() {
		this.lastActive = new Date();
		window.sessionStorage.setItem('rcla', this.lastActive.getTime().toString());
		this.active.next();
	}

	handleInactiveCheck() {

		if (!this.user) return;

		const diffInMinutes = differenceInMinutes(new Date(), this.lastActive);
		if (diffInMinutes > EXPIRE_IN_MINUTES) return this.signOut();

		this.updateLastActive();
	}

	signOut() {
		signOut(this.auth).then(() => {
			this.clearLastActive();
			this.router.navigate(['account/signin']);
			location.reload();
		});
	}

	clearLastActive() {
		window.sessionStorage.removeItem('rcla');
	}
}
