export class Person {
	address?: string;

	name: string;

	email?: string;

	constructor(partial: Partial<Person>) {
		Object.assign(this, partial);
	}
}
