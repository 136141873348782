
import { Person } from './person.typings';
import { RwUser } from './user.typings';

export class Practice {
	name: string;

	practiceId: string;

	zip: string;

	users: RwUser[] = [];

	dentistIds: string[];

	constructor(partial: Partial<Practice>) {
		Object.assign(this, partial);

		if (!Array.isArray(this.users)) {
			this.users = Object.keys(this.users as any ?? {}).map((userId) => ({
				userId,
				...this.users[userId]
			}));
		}
	}
}

export class Dentist extends Person {
	npi: string;

	practiceIds: string[];

	rawData?: any;

	constructor(partial: Partial<Dentist>) {
		super(partial);
		Object.assign(this, partial);
	}
}

export class NpiLookupCriteria {
	number: string;

	first_name: string;

	last_name: string;
}
