import { map } from 'rxjs';

const mapArray = (mapFn) => map((array) => array.map((item, index) => mapFn(item, index)));

/**
 * Generated bundle index. Do not edit.
 */

export { mapArray };

