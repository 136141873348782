import { computed, inject } from '@angular/core';
import { Auth, authState, EmailAuthProvider, multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, reauthenticateWithCredential, RecaptchaVerifier, sendEmailVerification, signOut, User } from '@angular/fire/auth';
import { collection, CollectionReference, doc, docData, DocumentReference, Firestore, updateDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { InactivityService } from '@rc/rev-cyclone/inactivity';
import { Practice, RwUser } from '@rc/typings/practice';
import { mapArray } from 'ngxtension/map-array';
import { combineLatest, filter, from, switchMap, tap } from 'rxjs';

interface State {
	fbUser: User | null;
	user: RwUser | null;
	loaded: boolean;
	practices: Practice[];
}

const initialState: State = {
	user: null,
	fbUser: null,
	loaded: false,
	practices: []
};

export const UserStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withHooks({
		async onInit(store, auth = inject(Auth), firestore = inject(Firestore)) {
			authState(auth).pipe(
				tap((fbUser) => {
					patchState(store, (state) => ({ ...state, fbUser, loaded: fbUser === null }));
				}),
				filter((fbUser) => !!fbUser),
				switchMap(({ uid }) => docData<RwUser>(doc(collection(firestore, '/users') as CollectionReference<RwUser>, uid) as DocumentReference<RwUser>, { idField: 'userId' })),
				tap((user) => patchState(store, (state) => ({ ...state, user, loaded: true }))),
				switchMap((user: RwUser) => combineLatest(user.practiceIds.map((practiceId) => docData<Practice>(doc(collection(firestore, '/practices') as CollectionReference<Practice>, practiceId) as DocumentReference<Practice>, { idField: 'practiceId' })))),
				mapArray((practice) => new Practice(practice))
			).subscribe((practices) => patchState(store, (state) => ({ ...state, practices })));
		}
	}),
	withComputed(({ fbUser, user }) => ({
		isLoggedIn: computed(() => !!fbUser()),
		emailIsVerified: computed(() => fbUser()?.emailVerified),
		mfaEnabled: computed(() => user()?.mfaEnabled),
		hasMultiplePractices: computed(() => user()?.practiceIds.length > 1)
	})),
	withMethods((store, auth = inject(Auth), router = inject(Router), firestore = inject(Firestore), inactivityService = inject(InactivityService)) => ({
		async signout() {
			await signOut(auth);
			inactivityService.clearLastActive();
			router.navigate(['/account/signin']);
		},
		async sendEmailVerification() {
			if (store.fbUser()?.emailVerified) return;
			await sendEmailVerification(store.fbUser());
			// TODO send toast message stating that email verification has been sent
		},
		async reload() {
			return from(store.fbUser().reload()).pipe(tap(() => {
				patchState(store, (state) => ({ ...state, fbUser: { ...store.fbUser() } }));
			}));
		},
		async reauthenticate(email: string, password: string) {
			const credential = EmailAuthProvider.credential(email, password);

			return reauthenticateWithCredential(store.fbUser(), credential);
		},
		async sendCode(password: string, phoneNumber: string, recaptchaVerifier: RecaptchaVerifier) {
			await this.reauthenticate(store.user().email, password);
			const session = await multiFactor(store.fbUser()).getSession();
			const phoneInfoOptions = { phoneNumber, session };
			const phoneAuthProvider = new PhoneAuthProvider(auth);
			const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
			return verificationId;
		},
		validateCode(verificationId: string, code: string) {
			const cred = PhoneAuthProvider.credential(verificationId, code);
			const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

			return multiFactorAssertion;
		},
		async enableMfa(verificationId: string, code: string) {
			const multiFactorAssertion = this.validateCode(verificationId, code);

			await multiFactor(store.fbUser()).enroll(multiFactorAssertion);

			return updateDoc(doc(firestore, `/users/${store.fbUser().uid}`), { mfaEnabled: true });
		},
		async updateUser(partial: Partial<RwUser>) {
			return updateDoc(doc(firestore, `/users/${store.fbUser().uid}`), partial);
		},
		async setLoaded(loaded: boolean) {
			patchState(store, (state) => ({ ...state, loaded }));
		}
	})),
);
